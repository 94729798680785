.video-games{
    &__list{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
        &__game{
            background: rgba(255, 255, 255, 0.1);
            padding: 1rem;
            h5{
                font-size: 1rem;
                font-weight: 500;
                text-align: center;
            }
            &__image{
                height: 10rem;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.1);
                transition: all 0.3s;
                transform: scale(0);
                &.loaded{
                    transform: scale(1);
                }
                img{
                    max-width: 100%;
                    max-height: 10rem;
                    opacity: 0;
                    transition: all 0.3s 0.3s;
                    &.loaded{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 992px){
    .video-games{
        &__list{
            grid-template-columns: 1fr 1fr;
        }
    }       
}
@media only screen and (max-width: 768px){
    .video-games{
        &__list{
            grid-template-columns: 1fr;
        }
    }
}