@import '../../style/mixins.scss';

.dashboard{
    &__logout{
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.3s;
        display: inline-block;
        cursor: pointer;
        padding: 0.5rem 1rem;
        &:hover{
            background: var(--secondary);
        }
    }
    &__content{
        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__movie-form{
        background: rgba(255, 255, 255, 0.1);
        padding: 1rem;
        h3{
            margin-top: 0;
        }
        form{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
        }
        &__field{
            background: rgba(255, 255, 255, 0.1);
            border: none;
            padding: 0.25rem 1rem;
            transition: all 0.3s;
            color: white;
            &::placeholder{
                color: #aaa;
            }
            &:not(#dashboard__movie-form__submit):focus, &.has-content{
                outline: none;
                background: white;
                color: black;
            }
        }
        #dashboard__movie-form__submit, #dashboard__movie-form__select{
            grid-column: 1/ span 2;
            text-transform: capitalize;
            &:hover{
                background: rgba(255, 255, 255, 0.5);
                cursor: pointer;
            }
        }
    }
}