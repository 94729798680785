.App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

*{
  box-sizing: border-box;
}

.content{
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
}
.content-container{
  grid-row-start: 1;
  grid-column-start: 1;
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0;
  background: var(--primary);
  color: white;
  font-family: var(--font);
  overflow-y: scroll;
}

.container{
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

.fade-enter{
  opacity: 0;
  z-index: 1;
  &.fade-enter-active{
    opacity: 1;
    transition: all 0.5s 0.5s;
    z-index: calc(var(--menu-index) + 2) !important;
    &.menu-active, &.menu-closing{
      transition: all 0.5s;
    }
  }
}

.fade-exit{
  opacity: 1;
  &.fade-exit-active{
    opacity: 0;
    transition: all 0.5s;
  }
}

.navbar a{
  color: white;
  text-decoration: none;
  transition: all 0.3s;
  &:hover{
    color: var(--secondary);
  }
}

@media only screen and (min-width: 576px){
  .container{
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px){
  .container{
    max-width: 720px;
  }
}
@media only screen and (min-width: 992px){
  .container{
    max-width: 960px;
  }
}
@media only screen and (min-width: 1200px){
  .container{
    max-width: 1140px;
  }
}