:root{
    --primary: #051020;
    --secondary: #e8bc1c;
    --tertiary: #0acf6d;
    --status: #1caae2;
    --error: #da2222;
    --secondary-dark: #835208;
    --menu-index: 50;
    --font: segoe, Roboto, sans-serif;
}

@font-face {
    font-family: segoe;
    font-style: normal;
    font-weight: normal;
    src: url('~/resources/fonts/Segoe UI.woff') format('woff');
  }
    
  
  @font-face {
    font-family: segoe;
    font-style: italic;
    font-weight: normal;
    src: url('~/resources/fonts/Segoe UI Italic.woff') format('woff');
  }
    
  
  @font-face {
    font-family: segoe;
    font-style: normal;
    font-weight: 500;
    src: url('~/resources/fonts/Segoe UI Bold.woff') format('woff');
  }
    
  
  @font-face {
    font-family: segoe;
    font-style: italic;
    font-weight: 500;
    src: url('~/resources/fonts/Segoe UI Bold Italic.woff') format('woff');
  }