.movies{
    & > .container{
        position: relative;
    }
    p{
        margin-bottom: 4rem;
        font-size: 1.25rem;
        text-align: center;
    }
    &-list{
        width: 100%;
        tr{
            display: grid;
            grid-template-columns: 4rem auto 4rem 4rem;
            gap: 1rem;
            padding: 0.5rem 1rem;
            th{
                text-align: left;
            }
        }
        .movie{
            padding: 0.5rem 1rem;
            &:nth-child(odd){
                background: rgba(255, 255, 255, 0.1);
            }
            &__name{
                text-align: left;
            }
        }
    }
    button{
        position: relative;
        background: rgba(255, 255, 255, 0.1);
        border: none;
        margin: 1rem 0;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 1rem;
        transition: all 0.3s;
        cursor: pointer;
        color: white;
        font-family: var(--font);
        &:hover{
            background-color: var(--secondary);
        }
    }
}
