.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__form{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        input{
            grid-column: span 6;
            background: rgba(255, 255, 255, 0.1);
            border: none;
            padding: 0.25rem 1rem;
            transition: all 0.3s;
            color: white;
            font-size: 1.5rem;
            &::placeholder{
                color: #aaa;
            }
            &:not(#dashboard__movie-form__submit):focus, &.has-content{
                outline: none;
                background: white;
                color: black;
            }
        }
        &__submit{
            grid-column: 6 / span 2 !important;
            margin: auto;
            &:hover{
                background: rgba(255, 255, 255, 0.5);
                cursor: pointer;
            }
        }
    }
    @media only screen and (max-width: 768px){
        &__form{
            grid-template-columns: 1fr;
            input, &__submit{
                grid-column: span 1 !important;
            }
        }
    }
}