.menu{
    z-index: calc(var(--menu-index) + 1);
    &__links{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
        &__link{
            color: white;
            text-decoration: none;
            transition: all 0.3s;
            font-size: 1.25rem;
            display: inline-block;
            &:hover{
                color: var(--secondary);
            }
        }     
    }
}