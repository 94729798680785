.content-section{
    background: rgba(255, 255, 255, 0.1);
    padding: 0.25rem 1rem 1rem;
    margin: 1rem 0;
    &:nth-child(2){
        background: transparent;
        padding: unset;
    }
    &__routes{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
.route{
    background: rgba(255, 255, 255, 0.1);
    padding: 0.25rem 1rem;
    &__header{
        display: flex;
        align-items: center;
        gap: 1rem;
        &__method{
            font-weight: bold;
            background: var(--primary);
            padding: 0.25rem 0.5rem;
            &.get{
                color: var(--secondary);
            }
            &.post{
                color: var(--tertiary);
            }
            &.patch{
                color: var(--status);
            }
            &.delete{
                color: var(--error);
            }
        }
    }
    &__content{
        padding-left: 1rem;
        .route__queries{
            &-item{
                &__parameter{
                    font-family:'Courier New', Courier, monospace;
                    font-weight: bold;
                    background: var(--primary);
                    color: var(--secondary);
                    padding: 0.25rem;
                }
            }
        }
        .route__required{
            &-item{
                &__parameter{
                    font-family:'Courier New', Courier, monospace;
                    font-weight: bold;
                    background: var(--primary);
                    color: var(--secondary);
                    padding: 0.25rem;
                }
            }
        }
        .route__body{
            &-item{
                &__parameter{
                    font-family:'Courier New', Courier, monospace;
                    font-weight: bold;
                    background: var(--primary);
                    color: var(--secondary);
                    padding: 0.25rem;
                }
            }
        }
    }
}