@import '../style/animations.scss';

.header{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    padding: 3rem 0;
    & > .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .headerLeft{
        display: flex;
        align-items: center;
        gap: 2rem;
        z-index: calc(var(--menu-index) + 1);
        & > a{
            color: white;
            text-decoration: none;
            transition: all 0.3s;
            &:hover{
                color: var(--secondary);
            }         
        }
    }
    h1{
        font-size: 2.5rem;
        font-weight: 500;
        margin: 0;
        position: relative;        
    }
    &__links{
        display: flex;
        align-items: center;
        gap: 2rem;
        color: var(--secondary);
        font-size: 2rem;
        a{
            color: var(--secondary);
        }
        &__github{
            &:hover{
                animation-name: spin;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
        }
        &__email{
            &:hover{
                animation-name: wobble;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;                
            }
        }
        &__resume{
            &:hover{
                animation-name: bounce;
                animation-duration: 1s;
                animation-timing-function: ease-out;
                animation-iteration-count: infinite;
            }
        }
    }
}

.navigation{
    &__button{
        width: 3rem;
        height: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 0.5rem;
        transition: all 0.3s;
        cursor: pointer;
        position: relative;
        z-index: calc(var(--menu-index) + 1);
        &:hover{
            background: rgba(255, 255, 255, 0.1);
        }       
        &__line{
            background: var(--secondary);
            height: 2px;
            width: 100%;
            border-radius: 2px;
            transition: all 0.3s;
            position: relative;
            &__hidden{
                position: absolute;
                background: var(--secondary);
                height: 2px;
                width: 100%;
                border-radius: 2px;
                transition: all 0.3s;
            }
        }
        &-open{
            .navigation__button__line{
                &.hiding-line{
                    transform: rotateZ(45deg);
                }
                &:not(.hiding-line){
                    transform: scaleX(0);
                    opacity: 0;
                }
                &__hidden{
                    transform: rotateZ(-90deg);
                }
            }
        }
    }
    &__slider{
        position: fixed;
        top: calc(-100% - 5rem);
        height: calc(100% + 5rem);
        left: 0;
        right: 0;
        z-index: var(--menu-index);
        background: var(--secondary-dark);
        transition: all 0.5s;
        clip-path: polygon(0% 0%, 0% 100%, 100% calc(100% - 5rem), 100% 0%);
        &-open{
            top: 0;
        }
    }
}

@media only screen and (max-width: 992px){
    .header{
        h1{
            text-align: center;
        }
        & > .container{
            flex-direction: column;
        }
        .headerLeft{
            flex-direction: column;
            gap: 0;
        }
    }
}