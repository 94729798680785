.project{
    &__title{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        a{
            font-size: 1.5rem;
            color: white;
            transition: all 0.3s;
            &:hover{
                color: var(--secondary);
            }
        }
    }
    &__image{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 100%;
            background: white;
            max-height: 30rem;
        }
    }
    &__tech-stack{
        h3{
            text-align: center;
        }
        & &__container{
            display: grid;
            gap: 1rem;
        }
        & &__skill{
            display: flex;
            align-items: center;
            justify-content: center;
            svg, p{
                font-size: 2rem;
                max-height: 2rem;
            }
            svg{
                height: 2rem;
            }
            p{
                font-size: 1rem;
            }
        }
    }
}