@keyframes spin{
    100%{
        transform: rotateY(360deg);
    }
}
@keyframes wobble{
    25%{
        transform: rotateZ(10deg);
    }
    75%{
        transform: rotateZ(-10deg);
    }
    100%{
        transform: rotateZ(0deg);
    }
}
@keyframes bounce{
    25%{
        transform: scale(0.95);
    }
    50%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
}