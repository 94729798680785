.footer{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    & .container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}