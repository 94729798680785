@import '../../style/mixins.scss';

.resume{
    padding: 1rem 0;
    .resume-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        .resume-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            opacity: 0;
            transform: translate(0, -20px);
            &.resume-transition-enter-active, &.resume-transition-enter-done{
                opacity: 1;
                transform: translate(0, 0);
            }
            h3{
                margin: 0;
                font-weight: 500;
            }
            h4{
                margin: 0;
                color: var(--secondary);
                font-weight: 400;
            }
        }
    }
}

.portfolio{
    .portfolio-container{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 1rem;
    }
    .portfolio-project{
        position: relative;
        width: 100%;
        aspect-ratio: 1;
        cursor: pointer;
        overflow: hidden;
        &.active{
            
        }
        &.inactive{
            .portfolio-project__tint{
                background: rgba(0, 0, 0, 0.1);
                backdrop-filter: saturate(0) brightness(0.25);
            }
        }
        &__tint{
            @include layer(-1, -1rem);
            transition: all 0.5s;
        }
        &__background{
            @include layer(-2);
            background-color: transparent;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: all 1s;
        }
        &__content{
            @include layer(0);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: all 0.5s;
            padding: 1rem;
            transform: translateY(10px);
        }
        &:not(.inactive){
            &:hover{
                .portfolio-project__tint{
                    background: rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(3px);
                }
                .portfolio-project__background{
                    transform: scale(1.1);
                }
                .portfolio-project__content{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            &.hovering{
                .portfolio-project__content{
                    transition: all 0.5s 0.25s;
                }
            }
        }
    }
    .portfolio-transition-container{
        grid-column: span 2;
        opacity: 0;
        position: relative;
        &:nth-child(3n + 1){
            transform: translate(-10px, 0);
            z-index: 3;
        }
        &:nth-child(3n + 2){
            transform: translate(calc(-100% - 1rem), 0);
            z-index: 2;
        }
        &:nth-child(3n + 3){
            transform: translate(calc(-200% - 2rem), 0);
            z-index: 1;
        }
        transform: scale(0);
        &.portfolio-transition-enter-active{
            opacity: 1;
            transform: translate(0, 0);
            .portfolio-project__background{
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
        &.portfolio-transition-enter-done{
            opacity: 1;
            transform: translate(0, 0);
            .portfolio-project__background{
                background-color: #ffffff;
            }
        }
        &.__last-project{
            grid-column: 3 / span 2;
        }
        &.__last-projects{
            grid-column: 2 / span 2;
            &.__last-project{
                grid-column: 4 / span 2;
            }
        }
    }
    a{
        color: white;
        text-decoration: none;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        text-align: center;
    }
}

.skills{
    padding: 2rem 0;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--primary);
    &.sticky{
        border-bottom: 1px solid var(--secondary);
        box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
    }
    .skills-container{
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 1rem;
        .skill-transition-container{
            opacity: 0;
            transform: translate(-10px, -2px);
        }
    }
    .skill{
        display: flex;
        align-items: center;
        justify-content: center;
        &.selected{
            color: var(--secondary);
            img{
                filter: drop-shadow(0 0 5px var(--secondary));
            }
        }
        svg, p{
            font-size: 2rem;
            transition: all 0.3s;
            cursor: pointer;
            max-height: 2rem;
            &:hover{
                color: var(--secondary);
            }
        }
        svg{
            height: 2rem;
        }
        p{
            font-size: 1rem;
        }
    }
    .skill-transition-container{
        &.skill-transition-enter-active, &.skill-transition-enter-done{
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

@media only screen and (max-width: 992px){
    .resume{
        & &-container{
            flex-direction: column;
        }
    }
    .portfolio{
        & &-container{
            grid-template-columns: repeat(4, 1fr);
        }
        & &-transition-container{
            &.__last-projects{
                grid-column: span 2;
                &.__last-project{
                    grid-column: 2 / span 2;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .portfolio{
        & &-container{
            grid-template-columns: 1fr;
        }
        & &-transition-container{
            grid-column: span 1;
            &.__last-projects{
                grid-column: span 1;
                &.__last-project{
                    grid-column: span 1;
                }
            }
            &.__last-project{
                grid-column: span 1;
            }
        }
    }
    .skills{
        & &-container{
            grid-template-columns: repeat(4, 1fr);
        }
    }
}