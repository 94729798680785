@import './../../style/mixins.scss';

.milo{ 
    p{
        margin-bottom: 4rem;
        font-size: 1.25rem;
        text-align: center;
    }
    &-gallery{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1rem;
        grid-auto-flow: dense;
        &__image{
            grid-column: span 2;
            grid-row: span 2;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.1);
            &.ultrawide{
                grid-column: span 8;
            }
            &.wide{
                grid-column: span 6;
            }
            &.tall{
                grid-column: span 4;
            }
            &-container{
                transition: all 0.5s 0.5s;
                transform: scale(0);
                opacity: 0;
            }
            &.loaded &-container{
                transform: scale(1);
                opacity: 1;
            }
            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
        &__video{
            grid-column: span 2;
            grid-row: span 2;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.1);
            &.ultrawide{
                grid-column: span 8;
            }
            &.wide{
                grid-column: span 6;
            }
            &.tall{
                grid-column: span 4;
            }
            &-container{
                transition: all 0.5s 0.5s;
                transform: scale(0);
                opacity: 0;
            }
            &.loaded &-container{
                transform: scale(1);
                opacity: 1;
            }
            video{
                max-height: 100%;
                max-width: 100%;
            }
        }
        &__loading{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 2rem;
            font-size: 1.5rem;
            background: var(--primary);
            box-shadow: 0 0 5px rgba(255, 255, 255, 0.25);
            transition: all 0.3s;
            &.loader-exit-active, &.loader-exit-done{
                opacity: 0;
            }
            &__progress{
                position: relative;
                background: rgba(255, 255, 255, 0.1);
                height: 5px;
                width: 100%;
                &-bar{
                    @include layer(1);
                    background: var(--tertiary);
                    transform : scaleX(0){
                        origin: left;
                    }
                }
            }
        }
    }
    .load-more{
        cursor: pointer;
    }
}
.return-button{
    position: fixed;
    z-index: 10;
    bottom: 4rem;
    right: 2rem;
    background: var(--primary);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
        background: var(--secondary);
    }
}
@media only screen and(max-width: 768px){
    .milo-gallery{
        grid-template-columns: 1fr;
        &__image{
            grid-column: span 1 !important;
            grid-row: span 1 !important;
        }
        &__video{
            grid-column: span 1 !important;
            grid-row: span 1 !important;
        }
    }
}